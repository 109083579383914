<template>
  <div id="master-data-peternak-view" data-app>
    
    <div class="card card-custom gutter-b">
      <div class="card-header card-header-tabs-line">
        <ul
          class="nav nav-dark nav-bold nav-tabs nav-tabs-line"
          role="tablist"
          ref="builder-tab"
        >
          <router-link
            to="/master-data/peternak"
            v-slot="{ href, navigate, isActive, isExactActive }"
          >
          <li class="nav-item">
            <a
              class="nav-link"
              role="tab"
              :href="href" 
              @click="navigate"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active'
              ]"
            >
              <span class="svg-icon svg-icon-md svg-icon-success m-0">
                <!--begin::Svg Icon-->
                <inline-svg src="media/svg/icons/Navigation/Left-2.svg"/>
                <!--end::Svg Icon-->
              </span>
              Kembali
            </a>
          </li>
          </router-link>
          <li class="nav-item">
            <a
              id="tab-menu-view"
              class="nav-link active cursor-pointer"
              v-on:click="setActiveTab"
              data-tab="0"
              data-toggle="tab"
              role="tab"
            >
              View Data
            </a>
          </li>
          <li class="nav-item">
            <a
              id="tab-menu-farm"
              class="nav-link cursor-pointer"
              v-on:click="setActiveTab"
              data-tab="1"
              data-toggle="tab"
              role="tab"
            >
              List Farm
            </a>
          </li>
        </ul>
      </div>

      <!--begin::View Data Peternak-->
        <div class="card-body">
          <b-tabs class="hide-tabs" v-model="tabIndex">
            <b-tab>
              <div class="mx-5" v-if="dataPeternak != null">
                <!--begin::Top-->
                <div class="d-flex align-items-center">
                    <!--begin::Symbol-->
                    <div class="symbol symbol-65 symbol-light d-sm-inline d-none mr-5">
                        <span class="symbol-label">
                          <span class="svg-icon svg-icon-sm-3x svg-icon-primary">
                            <!--begin::Svg Icon-->
                            <inline-svg src="media/svg/icons/General/Clipboard.svg"/>
                            <!--end::Svg Icon-->
                          </span>
                        </span>
                    </div>
                    <!--end::Symbol-->

                    <!--begin::Info-->
                    <div class="d-flex flex-column flex-grow-1">
                        <a class="text-dark-75 text-hover-primary mb-1 font-size-h5 font-weight-bolder">
                          {{ dataPeternak.kategoriusaha.uraian }} 
                        </a>
                        <div class="d-sm-flex d-block">
                            <div class="d-flex align-items-center mb-1">
                              <span class="svg-icon svg-icon-md svg-icon-primary mr-1">
                                <!--begin::Svg Icon-->
                                <inline-svg src="media/svg/icons/Communication/Shield-user.svg"/>
                                <!--end::Svg Icon-->
                              </span>                       
                              <span class="text-muted font-weight-bold">{{ dataPeternak.kode }}</span>
                            </div>
                        </div>
                        <div class="d-flex">
                          <b-badge :variant="dataPeternak.sts.color">{{ dataPeternak.sts.uraian }}</b-badge>
                        </div>
                    </div>
                    <!--end::Info-->

                </div>
                <!--end::Top-->

                <!--begin::Bottom-->
                <div class="pt-3">
                    <!--begin::Text-->
                    <div class="d-flex align-items-center pt-5 mb-2">
                      <span class="svg-icon svg-icon-lg svg-icon-primary mr-2">
                        <!--begin::Svg Icon-->
                        <inline-svg src="media/svg/icons/Map/Marker1.svg"/>
                        <!--end::Svg Icon-->
                      </span>                           
                      <span class="text-dark-75 font-size-lg font-weight-normal">
                          Provinsi {{ dataPeternak.provinsi.uraian }}, 
                          {{ dataPeternak.kabupaten.uraian }}, 
                          Kecamatan {{ dataPeternak.kecamatan.uraian }}, 
                          Desa {{ dataPeternak.desa.uraian }}, 
                          {{ dataPeternak.alamat }}
                      </span>
                    </div>
                    <!--end::Text-->

                    <!--begin::Separator-->
                    <div class="separator separator-solid mt-6 mb-4"></div>
                    <!--end::Separator-->

                    <!--begin::Description-->
                    
                    <div v-if="dataPeternak.kategoriusaha.identitas != 'NIK'" class="border rounded p-3 mb-5">
                      <div class="d-flex align-items-center mx-3">
                        <div class="symbol symbol-35 symbol-light mr-4">
													<span class="symbol-label">
                            <span class="svg-icon svg-icon-md svg-icon-primary">
                              <!--begin::Svg Icon-->
                              <inline-svg src="media/svg/icons/Communication/Group.svg"/>
                              <!--end::Svg Icon-->
                            </span> 
													</span>
												</div>         
                        <span class="text-dark-75 font-size-h5 font-weight-bolder">
                            Data {{ dataPeternak.kategoriusaha.uraian }}
                        </span>
                      </div>
                      
                      <!--begin::Separator-->
                      <div class="separator separator-solid mt-3 mb-4"></div>
                      <!--end::Separator-->

                      <div class="d-flex align-items-center mx-sm-7 mx-2">
												<!--begin::Symbol-->
												<div class="symbol symbol-35 symbol-light mr-4">
													<span class="symbol-label">
													</span>
												</div>
												<!--end::Symbol-->
												<!--begin::Text-->
												<div class="d-flex flex-column flex-grow-1">
													<a class="text-success text-hover-primary font-size-lg font-weight-bolder">{{ dataPeternak.nama }}</a>
                          <div class="d-sm-flex d-block">
                            <div class="d-flex align-items-center mr-6">
                              <span class="svg-icon svg-icon-lg svg-icon-primary mr-1">
                                <!--begin::Svg Icon-->
                                <inline-svg src="media/svg/icons/Shopping/Credit-card.svg"/>
                                <!--end::Svg Icon-->
                              </span>                       
                              <span class="text-muted font-weight-bold">NIB {{ dataPeternak.nib }}</span>
                            </div>
                          </div>
												</div>
												<!--end::Text-->
											</div>
                    </div>

                    <div class="border rounded p-3 mb-5">
                      <div class="d-flex align-items-center mx-3">
                        <div class="symbol symbol-35 symbol-light mr-4">
													<span class="symbol-label">
                            <span class="svg-icon svg-icon-md svg-icon-primary">
                              <!--begin::Svg Icon-->
                              <inline-svg src="media/svg/icons/Communication/Group.svg"/>
                              <!--end::Svg Icon-->
                            </span> 
													</span>
												</div>         
                        <span class="text-dark-75 font-size-h5 font-weight-bolder">
                            Penanggung Jawab
                        </span>
                      </div>
                      
                      <!--begin::Separator-->
                      <div class="separator separator-solid mt-3 mb-4"></div>
                      <!--end::Separator-->

                      <div class="d-flex align-items-center mx-sm-7 mx-2">
												<!--begin::Symbol-->
												<div class="symbol symbol-35 symbol-light mr-4">
													<span class="symbol-label">
													</span>
												</div>
												<!--end::Symbol-->
												<!--begin::Text-->
												<div class="d-flex flex-column flex-grow-1">
													<a class="text-success text-hover-primary font-size-lg font-weight-bolder">{{ dataPeternak.owner }}  (owner)</a>
                          <div class="d-sm-flex d-block">
                            <div class="d-flex align-items-center mr-6">
                              <span class="svg-icon svg-icon-lg svg-icon-primary mr-1">
                                <!--begin::Svg Icon-->
                                <inline-svg src="media/svg/icons/Shopping/Credit-card.svg"/>
                                <!--end::Svg Icon-->
                              </span>                       
                              <span class="text-muted font-weight-bold">{{ dataPeternak.owner_nik }}</span>
                            </div>
                            <div class="d-flex align-items-center mr-6">
                              <span class="svg-icon svg-icon-lg svg-icon-primary mr-1">
                                <!--begin::Svg Icon-->
                                <inline-svg src="media/svg/icons/Communication/Mail.svg"/>
                                <!--end::Svg Icon-->
                              </span>                       
                              <span class="text-muted font-weight-bold">{{ dataPeternak.owner_email }}</span>
                            </div>
                            <div class="d-flex align-items-center mr-6">
                              <span class="svg-icon svg-icon-lg svg-icon-primary mr-1">
                                <!--begin::Svg Icon-->
                                <inline-svg src="media/svg/icons/Communication/Active-call.svg"/>
                                <!--end::Svg Icon-->
                              </span>                       
                              <span class="text-muted font-weight-bold">+62{{ dataPeternak.owner_telp }}</span>
                            </div>
                          </div>
												</div>
												<!--end::Text-->
											</div>
                    </div>

                    <div class="border rounded p-3 mb-5">
                      <div class="d-flex align-items-center mx-3">
                        <div class="symbol symbol-35 symbol-light mr-4">
													<span class="symbol-label">
                            <span class="svg-icon svg-icon-md svg-icon-primary">
                              <!--begin::Svg Icon-->
                              <inline-svg src="media/svg/icons/Home/Commode1.svg"/>
                              <!--end::Svg Icon-->
                            </span> 
													</span>
												</div>         
                        <span class="text-dark-75 font-size-h5 font-weight-bolder">
                            Kategori Usaha
                        </span>
                      </div>
                      
                      <!--begin::Separator-->
                      <div class="separator separator-solid mt-3 mb-4"></div>
                      <!--end::Separator-->

                      <b-list-group>
                        <b-list-group-item v-for="(ush, index) in dataPeternak.usaha" :key="index">{{ ush.tipe.uraian }} {{ ush.komoditas.uraian }} {{ ush.tipeusaha.uraian }}</b-list-group-item>
                      </b-list-group>
                    </div>

                    <div class="border rounded p-3 mb-5">
                      <div class="d-flex align-items-center mx-3">
                        <div class="symbol symbol-35 symbol-light mr-4">
													<span class="symbol-label">
                            <span class="svg-icon svg-icon-md svg-icon-primary">
                              <!--begin::Svg Icon-->
                              <inline-svg src="media/svg/icons/Map/Marker1.svg"/>
                              <!--end::Svg Icon-->
                            </span> 
													</span>
												</div>         
                        <span class="text-dark-75 font-size-h5 font-weight-bolder">
                            Map Lokasi
                        </span>
                      </div>
                      
                      <!--begin::Separator-->
                      <div class="separator separator-solid mt-3 mb-4"></div>
                      <!--end::Separator-->

                      <div class="rounded iframe-content">
                        <div id="gmaps" ref="goomaps"></div>
                      </div>
                      
                    </div> 

                    <!--end::Description-->
                </div>
                <!--end::Bottom-->
              </div>

              <div v-else>
                <FacebookLoader></FacebookLoader>
              </div>
            </b-tab>
            <b-tab>
              <div class="mx-5" v-if="dataPeternak != null">
                <b-form-input
                autocomplete="off"
                id="input-peternak-1"
                v-model="form.namaFarm"
                class="form-control form-control-solid mb-5"
                required
                size="lg"
                placeholder="Search Farm Name..."
                ></b-form-input>

                <div v-if="filteredItem.length > 0">
                  <div v-for="(rinc, index) in filteredItem" :key="index" class="border rounded p-3 mb-5">
                    <div class="d-flex align-items-center mx-3">
                      <div class="symbol symbol-35 symbol-light mr-4">
                        <span class="symbol-label">
                          <span class="svg-icon svg-icon-md svg-icon-primary">
                            <!--begin::Svg Icon-->
                            <inline-svg src="media/svg/icons/Electric/Range-hood.svg"/>
                            <!--end::Svg Icon-->
                          </span> 
                        </span>
                      </div>         
                      <span class="text-dark-75 font-size-h5 font-weight-bolder">
                          <span class="mr-2">Farm {{ rinc.nama }}</span>
                          <b-button 
                          v-b-tooltip.hover title="Edit"
                          class="btn-icon pt-1"
                          variant="clean"
                          @click="$router.push(`/master-data/peternak/${idP}/farm/edit/${rinc.farm}`)">
                            <span class="svg-icon svg-icon-lg m-0">
                              <!--begin::Svg Icon-->
                              <inline-svg src="media/svg/icons/Design/Edit.svg"/>
                              <!--end::Svg Icon-->
                            </span>
                          </b-button>
                          
                          <b-button 
                          v-b-tooltip.hover title="Hapus"
                          class="btn-icon"
                          variant="clean"
                          @click="deletePopup(rinc,index)">
                            <span class="svg-icon svg-icon-lg m-0">
                              <!--begin::Svg Icon-->
                              <inline-svg src="media/svg/icons/General/Trash.svg"/>
                              <!--end::Svg Icon-->
                            </span>
                          </b-button>
                      </span>
                    </div>
                    
                    <!--begin::Separator-->
                    <div class="separator separator-solid mt-3 mb-4"></div>
                    <!--end::Separator-->

                    <!--begin::Top-->
                    <div class="d-flex align-items-center">
                        <!--begin::Info-->
                        <div class="d-flex flex-column flex-grow-1">
                          <div class="d-sm-flex d-block">
                              <div class="d-flex align-items-center mb-1 mr-sm-5">
                                <span class="svg-icon svg-icon-lg svg-icon-primary mr-1">
                                  <!--begin::Svg Icon-->
                                  <inline-svg src="media/svg/icons/Layout/Layout-arrange.svg"/>
                                  <!--end::Svg Icon-->
                                </span>                       
                                <span class="text-muted font-weight-bold">{{ rinc.sistemkandang.uraian }}</span>
                              </div>
                              <div class="d-flex align-items-center mb-1 mr-sm-5">
                                <span class="svg-icon svg-icon-lg svg-icon-primary mr-1">
                                  <!--begin::Svg Icon-->
                                  <inline-svg src="media/svg/icons/Home/Home.svg"/>
                                  <!--end::Svg Icon-->
                                </span>                           
                                <span class="text-muted font-weight-bold">{{ rinc.kepemilikan.uraian }} {{ rinc.kepemilikan_name ? `(${rinc.kepemilikan_name})` : '' }}</span>
                              </div>
                              <div class="d-flex align-items-center mb-1">                         
                                <b-badge :variant="rinc.sts.color">{{ rinc.sts.uraian }}</b-badge>
                              </div>
                          </div>
                        </div>
                        <!--end::Info-->

                    </div>
                    <!--end::Top-->

                    <!--begin::Bottom-->
                    <div class="pt-0">
                        <!--begin::Text-->
                        <div class="d-flex align-items-center pt-5 mb-2">
                          <span class="svg-icon svg-icon-lg svg-icon-primary mr-2">
                            <!--begin::Svg Icon-->
                            <inline-svg src="media/svg/icons/Map/Marker1.svg"/>
                            <!--end::Svg Icon-->
                          </span>                           
                          <span class="text-dark-75 font-size-lg font-weight-normal">
                              Provinsi {{ rinc.provinsi.uraian }}, 
                              {{ rinc.kabupaten.uraian }}, 
                              Kecamatan {{ rinc.kecamatan.uraian }}, 
                              Desa {{ rinc.desa.uraian }}, 
                              {{ rinc.alamat }}
                          </span>
                        </div>
                        <!--end::Text-->

                        <!--begin::Separator-->
                        <div class="separator separator-solid mt-6 mb-4"></div>
                        <!--end::Separator-->

                        <!--begin::Description-->
                        <div class="border rounded p-3 mb-5">
                          <div class="d-flex align-items-center mx-3">
                            <div class="symbol symbol-35 symbol-light mr-4">
                              <span class="symbol-label">
                                <span class="svg-icon svg-icon-md svg-icon-primary">
                                  <!--begin::Svg Icon-->
                                  <inline-svg src="media/svg/icons/General/Attachment2.svg"/>
                                  <!--end::Svg Icon-->
                                </span> 
                              </span>
                            </div>         
                            <span class="text-dark-75 font-size-h5 font-weight-bolder">
                                Rincian
                            </span>
                          </div>
                          
                          <!--begin::Separator-->
                          <div class="separator separator-solid mt-3 mb-4"></div>
                          <!--end::Separator-->

                          <b-row class="mx-sm-7 mx-2">
                            <b-col cols="12" sm="6" class="p-0">
                              <div class="d-flex flex-column flex-grow-1">
                                <span class="text-primary font-weight-bold">
                                  Jumlah Kandang
                                </span>
                                <span class="mb-2 text-muted font-weight-bold">
                                  {{ rinc.jumlah_kandang | nominal }} kandang
                                </span>
                              </div>
                            </b-col>

                            <b-col cols="12" sm="6" class="p-0">
                              <div class="d-flex flex-column flex-grow-1">
                                <span class="text-primary font-weight-bold">
                                  Jumlah Flock
                                </span>
                                <span class="mb-2 text-muted font-weight-bold">
                                  {{ rinc.jumlah_flock | nominal }} flok
                                </span>
                              </div>
                            </b-col>

                            <b-col cols="12" sm="6" class="p-0">
                              <div class="d-flex flex-column flex-grow-1">
                                <span class="text-primary font-weight-bold">
                                  Kapasitas
                                </span>
                                <span class="mb-2 text-muted font-weight-bold">
                                  {{ rinc.kapasitas | nominal }} ekor
                                </span>
                              </div>
                            </b-col>

                            <b-col cols="12" sm="6" class="p-0">
                              <div class="d-flex flex-column flex-grow-1">
                                <span class="text-primary font-weight-bold">
                                  Jumlah SDM
                                </span>
                                <span class="mb-2 text-muted font-weight-bold">
                                  {{ rinc.sdm | nominal }} orang
                                </span>
                              </div>
                            </b-col>
                          </b-row>
                        </div>
                        <!--end::Description-->
                    </div>
                    <!--end::Bottom-->
                  </div>
                </div>
                <div v-else class="border rounded p-3 mb-5 text-center">
                  <span class="text-dark-75 font-size-lg font-weight-bolder">Tidak ada data Farm</span>
                </div>
              </div>

              <div v-else>
                <FacebookLoader></FacebookLoader>
              </div>
            </b-tab>
          </b-tabs>
        </div>

    </div>

  <v-dialog
    v-model="dialogDelete"
    max-width="300">
      <v-card>
        <v-card-title class="headline">Hapus data</v-card-title>

        <v-card-text>
          Apakah anda yakin akan menghapus data <b>{{(dataHapus ? `Farm ${dataHapus.nama}` : '')}}</b> ? data yang dihapus tidak dapat dikembalikan lagi
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <b-button
          variant="link-success"
          class="m-3"
          @click="dialogDelete = false">
            BATAL
          </b-button>

          <b-button
          variant="link-danger"
          class="m-3"
          @click="onDelete">
            HAPUS
          </b-button>
        </v-card-actions>
      </v-card>
    </v-dialog>

  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { Loader } from '@googlemaps/js-api-loader';
import { FacebookLoader } from 'vue-content-loader'
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import PeternakService from "@/core/services/api/master/peternak.service";
import ErrorService from "@/core/services/error.service";

export default {
  name: "master-data-peternak-view",
  props: {
    idP: {
      required: true
    }
  },
  components: {
    FacebookLoader
  },
  data() {
    return {
      tabIndex: 0,
      dataPeternak: null,
      form: {
        namaFarm: "",
      },
      dialogDelete: false,
      dataHapus: null,
      dataIndex: null,
      redirectTab: true,
      gooMaps: null,
      gooMarker: null,
    }
  },
  computed: {
    ...mapGetters(["currentUser"]),
    filteredItem() {
      if (!this.dataPeternak) return []
      return this.dataPeternak.farm.filter((item) => item.nama.toLowerCase().includes(this.form.namaFarm.toLowerCase()));
    }
  },
  methods: {
    /**
     * Set current active on click
     * @param event
     */
    setActiveTab(event) {
      const tab = event.target.closest('[role="tablist"]');
      const links = tab.querySelectorAll(".nav-link");
      // remove active tab links
      for (let i = 0; i < links.length; i++) {
        links[i].classList.remove("active");
      }

      // set clicked tab index to bootstrap tab
      this.tabIndex = parseInt(event.target.getAttribute("data-tab"));

      // set current active tab
      event.target.classList.add("active");

      // keep active tab
      localStorage.setItem("builderTab", this.tabIndex);
    },  
    /**
     * Set current active on route
     * @param id
     */
    setActiveTabRoute(id) {
      if (id != 'farm' && id != 'view') {
        return
      }

      const event = document.getElementById(`tab-menu-${id}`)
      const tab = event.closest('[role="tablist"]');
      const links = tab.querySelectorAll(".nav-link");
      // remove active tab links
      for (let i = 0; i < links.length; i++) {
        links[i].classList.remove("active");
      }

      // set clicked tab index to bootstrap tab
      this.tabIndex = parseInt(event.getAttribute("data-tab"));

      // set current active tab
      event.classList.add("active");

      this.redirectTab = false
    }, 

    getData () {
      PeternakService.getPeternakSingle({ peternak: this.idP })
        .then((res) => {
            if (!res.data.status) {
                ErrorService.message(res.data)
                return;
            }
            this.dataPeternak = { ...res.data.data, geotag: JSON.parse(res.data.data.geotag) }
            
            this.$nextTick(() => {
              if (this.$route.query.tab && this.redirectTab) {
                this.setActiveTabRoute(this.$route.query.tab)
              }
              setTimeout(() => {
                this.initMaps()              
              }, 500);
            })
        })
        .catch((err) => ErrorService.status(err))
    },
    initMaps() {
      const loader = new Loader({
          apiKey: process.env.VUE_APP_GOOGLE_MAP_API_KEY,
          version: "weekly",
          libraries: ["places"]
      });

      loader.load()
        .then((google) => {
          this.gooMaps = new google.maps.Map(this.$refs.goomaps, {
            center: this.dataPeternak.geotag,
            zoom: 17,
            maId: "f7a6bf3173b56abc",
            controlSize: 25,
            mapTypeId: "roadmap",
            mapTypeControlOptions: {
              mapTypeIds: [],
            },

          });
          this.gooMarker = new google.maps.Marker({ position: this.dataPeternak.geotag, map: this.gooMaps });
        })
        .catch(err => {
            ErrorService.message({
              action: 'Peringatan',
              message: 'Maps gagal dimuat',
            })
        })
    },
    deletePopup(val, index) {
      this.dataHapus = { peternak: this.idP, farm: val.farm, nama: val.nama }
      this.dataIndex = index
      this.dialogDelete = true
    },
    onDelete () {
      if (! this.dataHapus) {
        return
      }
      PeternakService.deletePeternakFarm(this.dataHapus)
        .then((res) => {
            if (res.data.status) { 
              this.dataPeternak.farm.splice(this.dataIndex,1);     
            }
            ErrorService.message(res.data)
            this.dialogDelete = false
            this.dataHapus = null
            this.dataIndex = null
        })
        .catch((err) => {
          this.dialogDelete = false
          this.dataHapus = null
          this.dataIndex = null
          return ErrorService.status(err)
        })
    },

  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Master Data Peternak" }]);
    if (!this.currentUser.menu.peternak) {
      return this.$router.push({ name: 'dashboard'}).catch(()=>{})
    }
    this.getData()
  },
};
</script>
<style lang="scss">
  #master-data-peternak-view {
    .iframe-content {
      height: 400px;
      overflow: auto;
      
      #gmaps {
        height: 100%;
      }
    }
  }

</style>